export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
  },
  {
    title: "Usuários",
    route: "users-list",
    icon: "UserIcon",
  },
  {
    title: "Blaze",
    icon: "FileTextIcon",
    children: [
      {
        title: "Double",
        children: [
          {
            title: "Estratégias",
            route: "blaze-double-strategies",
          },
          {
            title: "Relatórios",
            route: "blaze-double-report",
          },
        ],
      },
      {
        title: "Crash",
        children: [
          {
            title: "Estratégias",
            route: "blaze-crash-strategies",
          },
        ],
      },
    ],
  },
  {
    title: "Esporte D.Sorte",
    icon: "FileTextIcon",
    children: [
      {
        title: "Aviator",
        children: [
          {
            title: "Estratégias",
            route: "apostaganha-crash-strategies",
          },
          {
            title: "Configurações",
            route: "aviator-config",
          },
        ],
      },
      {
        title: "Double",
        children: [
          {
            title: "Configurações",
            route: "eds-double-config",
          },
        ],
      },
    ],
  },
  {
    title: "Aposta Ganha",
    icon: "FileTextIcon",
    children: [
      {
        title: "Aviator",
        children: [
          {
            title: "Estratégias",
            route: "apostaganha-2-aviator-strategies",
          },
        ],
      },
    ],
  },
  {
    title: "Bet365",
    icon: "FileTextIcon",
    children: [
      {
        title: "Futebol Virtual",
        children: [
          {
            title: "Estratégias",
            route: "bet365-ev-strategies",
          },
        ],
      },
    ],
  },
];
